<template>
  <div class="access-error d-flex align-center justify-center flex-column fill-height">
    <img class="access-error__image mt-13 mt-md-0" src="/images/error-cat.svg" alt="" />
    <h1 class="text-h6 text-md-h5 mt-md-8 mt-4 font-weight-bold">{{ $t('access_denied.dont_have_access') }}</h1>
    <div class="text-subtitle-2 text-md-h6 mt-md-6 mt-2 text--secondary">
      {{ $t('access_denied.contact_your_manager') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccessErrorPage',
};
</script>

<style lang="scss">
.access-error {
  &__image {
    @media (max-width: map.get($--screens, 'sm')) {
      max-width: 304px;
    }
  }
}
</style>
